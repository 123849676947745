<template>
  <div>
    <v-toolbar tile flat elevation="1">
      <v-btn @click="$router.go(-1)" class="no-active" icon>
        <v-icon>$vuetify.icons.arrow_left</v-icon>
      </v-btn>
      <v-toolbar-title>Коммерческий кабинет</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-container>
      <v-list dense>
        <!-- <v-subheader inset> Кабинет </v-subheader> -->
        <v-list-item-group color="primary">
          <!-- <v-list-item
            v-if="currentUser && +currentUser.author"
            :to="{ name: 'CabinetStatisticsDay' }"
            class="mt-1"
            link
          >
            <v-list-item-icon>
              <v-icon color="grey darken-1">$vuetify.icons.wallet</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                >Заработано:
                {{ currentUser.income ? currentUser.income : 0 }}
                р.</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item> -->
          <v-list-item
            v-if="isAuthor"
            :to="{ name: 'CommerceDocuments' }"
            class="mt-1"
            link
          >
            <v-list-item-icon>
              <v-icon color="grey darken-1"
                >$vuetify.icons.file_document</v-icon
              >
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Договора</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- <v-list-item
            v-if="currentUser && +currentUser.author"
            :to="{ name: 'Page', params: { slug: 'prodavat-knigi' } }"
            class="mt-1"
            link
          >
            <v-list-item-icon>
              <v-icon color="grey darken-1">$vuetify.icons.cart</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Продавать книги</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->

          <!-- <v-list-item :to="{ name: 'CabinetTransactions' }" class="mt-1" link>
            <v-list-item-icon>
              <v-icon color="grey darken-1">$vuetify.icons.wallet_plus</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Транзакции</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
          <v-list-item
            v-if="isAuthor"
            :to="{ name: 'CabinetStatisticsDay' }"
            class="mt-1"
            link
          >
            <v-list-item-icon>
              <v-icon color="grey darken-1">$vuetify.icons.poll</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Статистика продаж</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="isAuthor"
            :to="{ name: 'PartnerIndex' }"
            class="mt-1"
            link
          >
            <v-list-item-icon>
              <v-icon color="grey darken-1">$vuetify.icons.account_group</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Партнерская программа</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- <v-list-item
            v-if="currentUser && +currentUser.author"
            :to="{ name: 'CabinetStatisticsPayouts' }"
            class="mt-1"
            link
          >
            <v-list-item-icon>
              <v-icon color="grey darken-1">$vuetify.icons.table_clock</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>История выплат</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
          
        </v-list-item-group>
      </v-list>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  data: () => ({}),
  methods: {},
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      //blogs: "cabinet_blogs/blogs"
    }),
    isAuthor() {
      if (this.currentUser && this.currentUser.author) {
        return true;
      }
      return false;
    },
  },
  mounted() {},
};
</script>
